import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "アドベントカレンダー2024を振り返ってみた",
  "date": "2025-01-22T02:54:30.000Z",
  "slug": "entry/2025/01/22/115430",
  "tags": [],
  "hero": "./thumbnail.png",
  "heroAlt": "アドベントカレンダー2024を振り返ってみた"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは！メドレー DevRel の重田です。`}<br parentName="p"></br>{`
`}{`2024 年 12 月に実施したアドベントカレンダー（以下、アドカレ）について、「実施の目的〜実施してどうだったのか」を振り返ろうと思います。`}</p>
    <p>{`▼Medley（メドレー） Advent Calendar 2024`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://qiita.com/advent-calendar/2024/medley"
      }}>{`https://qiita.com/advent-calendar/2024/medley`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "959px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/286c9ed3d38a726f0c77bb621df9e32b/d7abb/cal.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACOUlEQVQ4y21Ua2/iMBDM//9TfOhBP1Sq2kIP2ioQCHmQ9wMchziZ02xIy53O0ipre727MzuKBQDX6xV934vRxzDAGIOu63gtPm0YhrtYg7Zt5Z57xvLeulwu46PuilYr8c+tgWrHZFVZomMRAFqPCYaBfi++Uhdo3WBa1na7hW3b8H0Pb29vcF0Xrufh93qD/X4vttvtkOc5GEs/TTM4Owen0wlRFGGz2cDzPGitYa3Xa3x9fcEPAgmO4xiue8B8/gsPDw+yL4pCIPN8a9uIkwRJmqKqKuma78MwFNjWxNP9aq/XkUvh9+e+MwO+MffdjT/z11vreDxKB0opZFmG8/mMsqqkYtM0CIMT8ixHZwxsx0VelGjqEonrQDUadVUiSxN5TxSW4zjCAbmYz+dYLBbCB/mq6xosOJvNJOYUhnJOGsJTJHzTf35+lntO3dK30RO67/vSpbmDwe45GCZnB4K478UmSRFJf9tbCROYHsZ0UDcJlXWFKI6ExzjN5AFXEARoGo1GXRB6LtgMZVeU5Xcxa7VayTTZ8uLxEa+vrwLl/f1dpk5JLZdLgU4E3FNCtr3Fx8eHUEX5lGU5JgwCH4fDQUhlJ1Q74U8QOKRJCVPMRSnpjLrjt9FaTGRTpMk4fmOkCmHkWYYkGc/zPENdVZIozTLR4L1FcfxtqmlgESphctovLy8i0oPrIghDqc5zTvnp6Ql5UYigmfhf4zmLWhw7OSE0TnL6SUyLevz8/JSBkPwp4f++TPgH6D8nexZyeMIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "実施したカレンダーの内容一覧。25日分全てが埋まっている",
            "title": "実施したカレンダーの内容一覧。25日分全てが埋まっている",
            "src": "/static/286c9ed3d38a726f0c77bb621df9e32b/d7abb/cal.png",
            "srcSet": ["/static/286c9ed3d38a726f0c77bb621df9e32b/5a46d/cal.png 300w", "/static/286c9ed3d38a726f0c77bb621df9e32b/0a47e/cal.png 600w", "/static/286c9ed3d38a726f0c77bb621df9e32b/d7abb/cal.png 959w"],
            "sizes": "(max-width: 959px) 100vw, 959px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`なぜアドカレを実施したのか`}</h1>
    <p>{`メドレーは採用・広報・テックブログ運営の各チームで連携して日々発信しています。`}</p>
    <p>{`▼株式会社メドレー note`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://note.com/medley"
      }}>{`https://note.com/medley`}</a>{`  `}</p>
    <p>{`▼テックブログ MEDLEY Developer Portal`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entries/"
      }}>{`https://developer.medley.jp/entries/`}</a></p>
    <p>{`その中でアドカレを実施した理由は大きく二つです。`}</p>
    <ul>
      <li parentName="ul">{`社外に向けてメドレーエンジニア組織の情報発信量を増やすため`}</li>
      <li parentName="ul">{`事業部を超えたエンジニア同士の知見/情報交換の機会を増やすため`}</li>
    </ul>
    <p>{`そして何より、アドベントカレンダーという年末行事をみんなでワイワイ楽しみたい気持ちで実施しました 🎅`}</p>
    <p>{`こうした目的があり、テーマは絞らず社員一人一人のカラーが出るようにしました。`}<br parentName="p"></br>{`
`}{`（個人的にはカジュアルに楽しんで参加してもらいたい、テーマを絞ることでハードルを上げたくなかったという思いもありました）`}</p>
    <h1>{`どうやって運営をしたのか`}</h1>
    <h2>{`▼ はじめに決めたこと`}</h2>
    <p><strong parentName="p">{`実施の目的`}</strong><br parentName="p"></br>{`
`}{`この取り組みの背景を明確にし、その目的をしっかりと定義することで、全員が共通認識を持って取り組めるようにしました。`}</p>
    <p><strong parentName="p">{`執筆ガイドラインの作成`}</strong><br parentName="p"></br>{`
`}{`社外に向けて発信する記事であるため、記載ルールを明確に設定しました。ガイドラインがあることで、レビュー時の手戻りを最小限に抑え、スムーズに運営することを目指しました。`}</p>
    <p><strong parentName="p">{`執筆場所（Qiita、Zenn、はてなブログなど）`}</strong><br parentName="p"></br>{`
`}{`執筆する媒体については、基本的に各自にお任せしました。各媒体での個人アカウントを用いて記事を作成し、その後、企業アカウントに紐付けることで管理を統一しています。`}</p>
    <p><strong parentName="p">{`執筆〜公開までのタスクをドキュメント化`}</strong><br parentName="p"></br>{`
`}{`執筆から公開に至るまでのタスクを具体的にドキュメント化しました。これにより、全員が同じフローに沿って円滑に作業を進めることができました。`}</p>
    <h2>{`▼ 気をつけたこと`}</h2>
    <p>{`メドレーでは今回のアドカレをはじめ、社外へ情報発信をする際はしっかりとレビューをする体制を敷いています。`}<br parentName="p"></br>{`
`}{`アドカレについては、公開前に必ずエンジニア視点での技術レビューと広報視点でのレビューのダブルチェックを実施していました。また、公開後は CTO・テックブログ運営チームで最終チェックをしていました。`}<br parentName="p"></br>{`
`}{`今回のアドカレはカジュアルに楽しんで参加してもらう取り組みなので自由に執筆してもらいましたが、上場企業としてダブルチェックは欠かせません...！`}</p>
    <h1>{`社内の反応`}</h1>
    <p>{`スタート時は不安もありましたが、各方面から協力があり無事に枠を埋めることができました 🙌`}</p>
    <p>{`「ブログ書こう！」と積極的に呼びかけてくれる場面もあり、メドレー社員の温かさにほっこりしていました...感謝です！`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "902px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bb2248b49fd8b1f39f37a9fddca971be/58213/slack1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAABKElEQVQY0y2OyW7jMBBE9f8/N4cgmIlhazNJkeIqUpKXHF5ATg6NalR3Ld2fy18++oV/08LXtHCVlmFx3JRl1IFh8fS/c5MWGxLnfmC9JGbN6yyc50ncMvtx0sW8cVGJq7Dc7ppBOaYqFoZZ+zbC/MdeaKS2KBOY+wtWC2J58ng8+H6/eb9edM/nC6Et98UhTWg4KYtaIy5spK0Q4kZIdc/EtOFDIqRMSKXxORdijKSU6Grdvu8R4o4UAqM167pi1xUhBN45Ygh479qt8pWzvz/LsiClbFh1Xa2rlGIcJ+Z5RhuDryLv0VrjnGe1tjWw1ja+lJ2cM9u24ZzDGNOw/jTDmjCNE8Mw0I8j6vOTsK6EGNtzvdf0GlBbV2E1q6ZVfxwHpRT2fecH2s/BnHSXaF0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Slackでの投稿内容1つ目。記載内容は以下の通り。「〇〇でも周知されてましたが、よければメドレーのアドベントカレンダーに寄稿してみませんか。今回は会社の developer blog に載せるというわけでもなく、 Qiita / Zenn / はてなブログ / 個人ブログ もOKと、かなりカジュアルに執筆できる環境です！〇〇も一本記事を書く予定で、初めてZennの環境構築してみて、うおーめっちゃ体験良い！、となったので、今後の自身の記事執筆のリズム作りの一発目とかにもよさそう。忙しいところはありつつ、内容はちょっとしたものとかでも全然構わない（多分）はずなので、〇〇チームからいっぱい出せたらなーと思っています！わくわく。こういうのが将来的に採用にも繋がると思っていて、内部からも外部からも魅力的な開発組織にしていきたいですー」記載内容ここまで。",
            "title": "Slackでの投稿内容1つ目。記載内容は以下の通り。「〇〇でも周知されてましたが、よければメドレーのアドベントカレンダーに寄稿してみませんか。今回は会社の developer blog に載せるというわけでもなく、 Qiita / Zenn / はてなブログ / 個人ブログ もOKと、かなりカジュアルに執筆できる環境です！〇〇も一本記事を書く予定で、初めてZennの環境構築してみて、うおーめっちゃ体験良い！、となったので、今後の自身の記事執筆のリズム作りの一発目とかにもよさそう。忙しいところはありつつ、内容はちょっとしたものとかでも全然構わない（多分）はずなので、〇〇チームからいっぱい出せたらなーと思っています！わくわく。こういうのが将来的に採用にも繋がると思っていて、内部からも外部からも魅力的な開発組織にしていきたいですー」記載内容ここまで。",
            "src": "/static/bb2248b49fd8b1f39f37a9fddca971be/58213/slack1.png",
            "srcSet": ["/static/bb2248b49fd8b1f39f37a9fddca971be/5a46d/slack1.png 300w", "/static/bb2248b49fd8b1f39f37a9fddca971be/0a47e/slack1.png 600w", "/static/bb2248b49fd8b1f39f37a9fddca971be/58213/slack1.png 902w"],
            "sizes": "(max-width: 902px) 100vw, 902px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "754px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0b55ade787e6e228446a5386ee88f41b/7527b/slack2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "10.333333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAYAAABYBvyLAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAdUlEQVQI1z2NMQ7DIBRDc//7tFMi9QTNxIIQJAU+YQAkGIDJVb/UDE+yLcteSs54rRsOc0BrzSilcBEhpXRTSkGtFWMMZs7J/HTv/c6XnDKejxXn+YExBkII7O+dD4gI3ntIKeGcYx9jRAgB1lrutNZ4+D/6BdtWlIThjp7VAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Slackでの投稿内容2つ目。記載内容は以下の通り。「アドカレを埋め尽くすぞ！（有志）のカレンダーでも入れるか」記載内容ここまで。",
            "title": "Slackでの投稿内容2つ目。記載内容は以下の通り。「アドカレを埋め尽くすぞ！（有志）のカレンダーでも入れるか」記載内容ここまで。",
            "src": "/static/0b55ade787e6e228446a5386ee88f41b/7527b/slack2.png",
            "srcSet": ["/static/0b55ade787e6e228446a5386ee88f41b/5a46d/slack2.png 300w", "/static/0b55ade787e6e228446a5386ee88f41b/0a47e/slack2.png 600w", "/static/0b55ade787e6e228446a5386ee88f41b/7527b/slack2.png 754w"],
            "sizes": "(max-width: 754px) 100vw, 754px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`また、以下のようにポジティブな反応をもらうことができました ✨`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "902px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7634a64f700bfdf3a0e18bdb0d5a15eb/58213/positive.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "9.666666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAYAAABYBvyLAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAbUlEQVQI1z2OywoDIQxF5/+/r4sitSgiiOBGMhHj4xZDmcXhkORyyfX6OAwRMDe01rDWwt4bc071GEPpvT+ziDz7P/vkAdzX++vhnIO1Ft57hBDUMUaUUpBzhjFGSSnpvdYKIgIzq4lon2dO4Q+91Jjg0IYVcQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Slackでの投稿内容。記載内容は以下の通り。「アドベントカレンダー、チーム内のデイリースクラムで毎日紹介していいね押してもらうということをやっていて、毎日社内記事読んで感想言うの楽しいというチーム内の意見も出てきています！」記載内容ここまで。",
            "title": "Slackでの投稿内容。記載内容は以下の通り。「アドベントカレンダー、チーム内のデイリースクラムで毎日紹介していいね押してもらうということをやっていて、毎日社内記事読んで感想言うの楽しいというチーム内の意見も出てきています！」記載内容ここまで。",
            "src": "/static/7634a64f700bfdf3a0e18bdb0d5a15eb/58213/positive.png",
            "srcSet": ["/static/7634a64f700bfdf3a0e18bdb0d5a15eb/5a46d/positive.png 300w", "/static/7634a64f700bfdf3a0e18bdb0d5a15eb/0a47e/positive.png 600w", "/static/7634a64f700bfdf3a0e18bdb0d5a15eb/58213/positive.png 902w"],
            "sizes": "(max-width: 902px) 100vw, 902px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`最終的には目的であった、`}</p>
    <ul>
      <li parentName="ul">{`社外に向けてメドレーエンジニア組織の情報発信量を増やすため`}</li>
      <li parentName="ul">{`事業部を超えたエンジニア同士の知見/情報交換の機会を増やすため`}</li>
    </ul>
    <p>{`のどちらも実現できたと考えています。`}</p>
    <h1>{`最後に`}</h1>
    <p>{`人気があった記事や個人的に面白かったブログをいくつかピックアップします。`}<br parentName="p"></br>{`
`}{`他にも素敵なブログがたくさんあるのでぜひご一読いただけると嬉しいです🙌✨`}</p>
    <p>{`耳コピアプリの個人開発記録：ドキュメントドリブンで描くプロダクト設計 `}<a parentName="p" {...{
        "href": "https://qiita.com/nayucolony"
      }}>{`_@nayucolony`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://zenn.dev/medley/articles/personal-project-with-document-driven"
      }}>{`https://zenn.dev/medley/articles/personal-project-with-document-driven`}</a></p>
    <p>{`トイルの削減も、情報漏洩リスクの削減も、両方手に入れる。IAM Identity Center は欲張りなんだ `}<a parentName="p" {...{
        "href": "https://x.com/yujittttttt"
      }}>{`_@yujittttttt`}</a>{`
`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2024/12/03/100122"
      }}>{`https://developer.medley.jp/entry/2024/12/03/100122`}</a>{` `}</p>
    <p>{`私と地域 Ruby コミュニティとメドレーの関わり `}<a parentName="p" {...{
        "href": "https://x.com/Kirika_K2"
      }}>{`_@Kirika_K2`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2024/12/12/180014"
      }}>{`https://developer.medley.jp/entry/2024/12/12/180014`}</a></p>
    <p>{`エンジニアがユーザーヒアリングやってみた `}<a parentName="p" {...{
        "href": "https://qiita.com/eriririri"
      }}>{`_@eriririri`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://qiita.com/eriririri/items/738274113dbc93e10d82"
      }}>{`https://qiita.com/eriririri/items/738274113dbc93e10d82`}</a></p>
    <p>{`ルビと格闘した話 `}<a parentName="p" {...{
        "href": "https://qiita.com/rio-song"
      }}>{`_@rio-song`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://qiita.com/rio-song/items/94879fc82ce90253c5b4"
      }}>{`https://qiita.com/rio-song/items/94879fc82ce90253c5b4`}</a></p>
    <p>{`EM やテックリードにチャレンジすることになったら考えたい「時間」のこと `}<a parentName="p" {...{
        "href": "https://ymzkmct.hatenablog.com/about"
      }}>{`_@ymzkmct`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://ymzkmct.hatenablog.com/entry/2024/12/22/100000"
      }}>{`https://ymzkmct.hatenablog.com/entry/2024/12/22/100000`}</a></p>
    <p>{`1,000 人を超える規模の組織で、全社生成 AI 推進プラットフォームとして Dify を導入し始めた話 `}<a parentName="p" {...{
        "href": "https://x.com/terukura"
      }}>{`_@terukura`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://zenn.dev/medley/articles/54ad12a3557656"
      }}>{`https://zenn.dev/medley/articles/54ad12a3557656`}</a></p>
    <h1>{`We’re hiring`}</h1>
    <p>{`メドレーでは一緒に働く仲間を大募集しています！`}<br parentName="p"></br>{`
`}{`カジュアル面談も実施しておりますので、少しでもご興味のある方はぜひご応募お待ちしております！`}</p>
    <p>{`募集の一覧`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>
    <p>{`医療エンジニアリング領域盛り上がっています！メドレーについてお話します！`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://pitta.me/matches/BtcyDvCvUZtx"
      }}>{`https://pitta.me/matches/BtcyDvCvUZtx`}</a></p>
    <p>{`メドレーの開発チームについて知りたい方！ぜひお話ししましょう！`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://pitta.me/matches/sNeEHMdSLZpB"
      }}>{`https://pitta.me/matches/sNeEHMdSLZpB`}</a></p>
    <p>{`株式会社メドレーのデザインについてお話します！`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://pitta.me/matches/vshCmTBHhzCQ"
      }}>{`https://pitta.me/matches/vshCmTBHhzCQ`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      